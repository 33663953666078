import * as React from "react"

const Lunch1 = () => (
  <div className="text-xl">
    <p>
      <em className="text-2xl">109 kr</em>
    </p>
    <p>
      Onsdag - fredag <em>11:30 - 14:30</em>
    </p>

    <p>
      <b>Onsdag</b>
      <br />
      Grillad kycklingfilé med dragonsås
      <br />
      Stekt sejfilé med hummersås
      <br />
      Pasta med pepparsalami, grädde & parmesan
    </p>

    <p>
      <b>Torsdag</b>
      <br />
      Pannbiff med champinjonsås
      <br />
      Stekt koljafilé med basilikasås
      <br />
      Pasta med pastrami, pesto & grädde
    </p>

    <p>
      <b>Fredag</b>
      <br />
      Fläskfilé Black & White
      <br />
      Fiskgratäng med broccoli & lök
      <br />
      Pasta med skaldjur, tomatsås & vitlök
    </p>

    <p>
      <b>Veckans vegetariska</b>
      <br />
      Pasta med champinjon, spenat & vitvinssås
    </p>

    <p>
      <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
      <br />
      Avhämtning: 99 kr
    </p>
  </div>
)

export default Lunch1
